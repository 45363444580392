import React, { createContext, useCallback, useState, useEffect } from 'react'
import { useSelector } from '~/lib/hooks/redux'
import { getAccountData } from '~/state/ducks/accounts/selectors'

export const ViewHistoryContext = createContext<string[]>([])
export const AddViewHistoryContext = createContext<(itemUid: string) => void>(() => {})

export default function ViewHistoryProvider({ children }: { children: React.ReactNode }) {
  const account = useSelector(getAccountData)
  const [viewHistory, setViewHistory] = useState<string[]>([])

  const fetchStorageData = useCallback<() => string[]>(() => {
    const storageKey = `view_history_${account !== null ? account.userId : 'nologin'}`
    const storageData = localStorage.getItem(storageKey)
    if (storageData === null) {
      return []
    }
    return JSON.parse(storageData)
  }, [account])

  useEffect(() => {
    if (account === undefined) {
      return
    }
    const storageData = fetchStorageData()
    setViewHistory(storageData)
  }, [account])

  const addViewHistory = useCallback(
    (itemUid: string) => {
      if (account === undefined) {
        return
      }
      const storageData = fetchStorageData()
      const storageKey = `view_history_${account !== null ? account.userId : 'nologin'}`
      const newViewHistory = [itemUid, ...storageData.filter(id => id !== itemUid)].slice(0, 12)
      localStorage.setItem(storageKey, JSON.stringify(newViewHistory))
      setViewHistory(newViewHistory)
    },
    [account]
  )

  return (
    <ViewHistoryContext.Provider value={viewHistory}>
      <AddViewHistoryContext.Provider value={addViewHistory}>
        {children}
      </AddViewHistoryContext.Provider>
    </ViewHistoryContext.Provider>
  )
}
