import { createSelector } from 'reselect'
import { DataState } from './reducers'
import { RootState } from '../types'

export const accountsDataSelector = (state: Pick<RootState, 'accounts'>) => state.accounts.data

export const getAccountData = createSelector(accountsDataSelector, (state: DataState) => {
  return state
})

export const isLogin = createSelector(accountsDataSelector, (state: DataState) => {
  return state !== null
})

export default {
  getAccountData,
  isLogin
}
