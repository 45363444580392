import { ItemData, PurchaseType } from '~/types'

const currency = 'JPY'

export function addToWishlistEvent(item: ItemData) {
  gtagWrap('event', 'add_to_wishlist', {
    currency,
    value: item.price,
    items: [formatItem(item)]
  })
}

export function addPaymentInfoEvent(item: ItemData, type) {
  gtagWrap('event', 'add_payment_info', {
    currency,
    payment_type: 'Credit Card',
    value: item.price,
    items: [formatItem(item)],
    type
  })
}

export function purchaseEvent(item: ItemData, type: PurchaseType) {
  gtagWrap('event', 'purchase', {
    currency,
    value: item.price,
    items: [formatItem(item)],
    type
  })
}

export function beginCheckoutEvent(item: ItemData, type) {
  gtagWrap('event', 'begin_checkout', {
    currency,
    value: item.price,
    items: [formatItem(item)],
    type
  })
}

export function sendLoginEvent(method: string) {
  gtagWrap('event', 'login', {
    method
  })
}

export function sendSignUpEvent(method: string) {
  gtagWrap('event', 'sign_up', {
    method
  })
}

export function sendUpdateEmail() {
  gtagWrap('event', 'update_email')
}

export function sendUpdatePassword() {
  gtagWrap('event', 'update_password')
}

const gtagWrap: Gtag.Gtag = function (...args) {
  if (window.gtag === undefined) {
    return
  }
  gtag(args[0], args[1], args[2])
}

function formatItem(item: ItemData) {
  return {
    item_id: item.itemUid,
    item_name: item.title,
    price: item.price,
    item_variant: item.isAdult ? 'r18' : 'general',
    currency,
    quantity: 1
  }
}
