import { Close } from '@material-ui/icons'
import IconButton from '~/components/molecules/IconButton'
import styled from 'styled-components'

interface Props {
  title?: string
  onClose?(): void
  className?: string
}

export default function Header({ title, onClose, className }: Props) {
  return (
    <Container className={className}>
      <div>
        {onClose !== undefined && (
          <IconButton Icon={<Close style={{ fontSize: 24 }} />} size={56} onClick={onClose} />
        )}
      </div>
      {title !== undefined && <Title>{title}</Title>}
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
`

const Title = styled.p`
  ${({ theme }) => theme.fonts.Bold24};
  margin: 0;
  color: ${({ theme }) => theme.colors.OnSurface2};
  text-align: center;
`
