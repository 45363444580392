import React, { useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { SlideBody, ModalBody } from './Body'

interface Props {
  show: boolean
  title?: string
  type?: 'slide' | 'modal' | 'raw'
  scrollable?: boolean
  children: React.ReactNode
  onClose(): void
}

export default function Modal({
  show,
  title,
  type = 'modal',
  scrollable = false,
  onClose,
  children
}: Props) {
  const portal = useRef<HTMLDivElement>()

  useEffect(() => {
    portal.current = document.body.appendChild(document.createElement('div'))

    return () => {
      if (portal.current !== undefined) {
        portal.current.remove()
      }
      document.documentElement.style.overflow = null
    }
  }, [])

  // モーダルが開いている間背面のスクロール防止
  useEffect(() => {
    document.documentElement.style.overflow = show ? 'hidden' : null
  }, [show])

  if (!show || portal.current === undefined) {
    return null
  }

  const modalBody =
    type === 'raw' ? (
      children
    ) : type === 'slide' ? (
      <SlideBody onClose={onClose}>{children}</SlideBody>
    ) : (
      <ModalBody title={title} scrollable={scrollable} onClose={onClose}>
        {children}
      </ModalBody>
    )

  return ReactDOM.createPortal(modalBody, portal.current)
}
