import Link from 'next/link'
import styled from 'styled-components'
import ActiveDot from './ActiveDot'

interface Props {
  active: boolean
  pathname: string
  icon: React.ReactNode
}

export default function MenuBody({ active, pathname, icon }: Props) {
  return (
    <Container>
      <LinkWrapper>
        <Link
          href={{
            pathname
          }}
          passHref
        >
          <IconImageWrapper active={active}>{icon}</IconImageWrapper>
        </Link>
      </LinkWrapper>
      {active && <ActiveDot />}
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 12px 1fr 12px;
  grid-template-areas:
    '_'
    'icon'
    'dot';
  justify-items: center;
`

const LinkWrapper = styled.div`
  grid-area: icon;
`

const IconImageWrapper = styled.div<{ active: boolean }>`
  width: 48px;
  height: 48px;
  color: ${({ theme, active }) => (active ? theme.colors.OnSurface4 : theme.colors.OnSurface5)};
  cursor: pointer;
`
