import React, { createContext, useReducer, Dispatch } from 'react'

type ACTION = { type: 'set'; payload: { status: number } } | { type: 'reset' }

interface Status {
  status: number
}

const initialState = { status: 200 }

export const ErrorStatusContext = createContext<{ state: Status; dispatch: Dispatch<ACTION> }>(null)

function statusReducer(state: Status, action: ACTION) {
  switch (action.type) {
    case 'set': {
      return { status: action.payload.status }
    }
    case 'reset': {
      return { ...initialState }
    }
    default: {
      return state
    }
  }
}

export function ErrorStatusProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(statusReducer, initialState)
  const value = {
    state,
    dispatch
  }

  return <ErrorStatusContext.Provider value={value}>{children}</ErrorStatusContext.Provider>
}
