export const mediaConfig = {
  desktop: '800px',
  desktopWide: '1280px'
} as const

export const COLUMN = 80
export const GAP = 16
export const calculateColumns = (column: number) => COLUMN * column + GAP * (column - 1)

export const CategoryMap = {
  illustration: 'イラスト',
  manga: 'マンガ',
  novel: '小説',
  music: '音楽',
  voice: '音声',
  idol: 'アイドル',
  cosplay: 'コスプレ',
  photo: '写真',
  video: '映像',
  game: 'ゲーム',
  trpg: 'TRPG',
  other: 'その他'
} as const

export const PayoutStatusMap = {
  PENDING: '振込待ち',
  FINISHED: '振込完了',
  FAILED: '振込失敗'
} as const

export const BankTypeMap = {
  NORMAL: '普通',
  CURRENT: '当座',
  SAVINGS: '貯蓄'
} as const

export const PurchaseTypeMap = {
  gift: '布教用',
  private: '自分用'
} as const

export const allowImageMimeTypes = ['image/png', 'image/jpg', 'image/jpeg']
export const allowZipMimeTypes = ['application/zip', 'application/x-zip-compressed']

export const bankTransferFee = 300 as const
export const CreditCardTransactionRate = 0.05 as const
export const AmbassTransactionRate = 0.05 as const

export const maxCountZip = 1
export const maxCountSampleImage = 10
export const maxSizeThumbnail = 2 * 1000 * 1000
export const maxSizeSampleImage = 2 * 1000 * 1000
export const maxSizeZip = 1000 * 1000 * 1000 // 1GBまでアップロード可能

export const maxDescriptionLength = 1024 as const

export const contactFormUrl =
  'https://docs.google.com/forms/d/e/1FAIpQLScvp93natLwhQt3G-O3i2-irVm3DU1mAxNpdjiLYByeKugE5w/viewform'
