import styled from 'styled-components'
import Link from 'next/link'
import UserIconBody from './UserIconBody'
import { useUserIconImage } from '~/lib/hooks/image'
import { Size, BorderType } from './types'

interface UserIconProps {
  userId: string
  name: string
  url: string
  size?: Size
  link?: boolean
  border?: BorderType
}
export default function UserIcon({
  size = 64,
  name,
  userId,
  url,
  link = true,
  border = null
}: UserIconProps) {
  const [imageUrl, handleError] = useUserIconImage(url)

  if (link) {
    return (
      <StyledLink size={size} href={`/users/${userId}`} passHref>
        <UserIconBody
          size={size}
          url={imageUrl}
          border={border}
          name={name}
          onError={handleError}
        />
      </StyledLink>
    )
  }
  return (
    <UserIconBody size={size} url={imageUrl} border={border} name={name} onError={handleError} />
  )
}

const StyledLink = styled(Link)<{ size: Size }>`
  display: block;
  border-radius: 50%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  cursor: pointer;
`
