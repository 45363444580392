import { createSelector } from 'reselect'
import { DataState } from './reducers'
import { RootState } from '../types'

export const loadingDataSelector = (state: Pick<RootState, 'loading'>) => state.loading.data

export const getLoadingData = createSelector(loadingDataSelector, (state: DataState) => {
  return state
})

export default {
  getLoadingData
}
