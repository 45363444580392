import types from './types'
import * as Actions from './actions'

import { ActionType } from 'typesafe-actions'

export type DataState = boolean

export interface LoadingState {
  data: DataState
}

export type LoadingAction = ActionType<typeof Actions>

const loadingReducer = (state: LoadingState = { data: false }, action: LoadingAction) => {
  switch (action.type) {
    case types.SET_LOADING:
      const { loading } = action.payload
      return {
        data: loading
      }
    default:
      return state
  }
}

export default loadingReducer
