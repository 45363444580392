import { Dispatch } from 'redux'
import { SET_TOAST, RESET_TOAST } from './types'
import { ToastType } from '~/types'

export function setToast(params: { type: ToastType; text: string; path?: string }) {
  return async (dispatch: Dispatch) => {
    const { type, text, path } = params

    return dispatch({
      type: SET_TOAST,
      payload: {
        type,
        text,
        path
      }
    })
  }
}

export function resetToast() {
  return async (dispatch: Dispatch) => {
    return dispatch({
      type: RESET_TOAST
    })
  }
}

export default {
  setToast
}
