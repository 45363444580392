import axios from '../../../lib/axios'
import { Dispatch } from 'redux'
import { SET_ACCOUNT, SET_NOT_LOGIN_ACCOUNT } from './types'
import { RootState } from '../types'
import { AccountData, Social } from '~/types'

export function fetchAccount(force: boolean = false) {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()
    // すでにアカウントデータが入っている場合はもう取得しない
    if (!force && state.accounts.data !== undefined && state.accounts.data !== null) {
      return
    }
    const res = await axios<AccountData | ''>({ url: '/users/account' }, { dispatch })
    if (res === '') {
      return dispatch({
        type: SET_NOT_LOGIN_ACCOUNT
      })
    }
    const {
      name,
      userId,
      email,
      emailVerified,
      profileImageUrl,
      coverImageUrl,
      isAdultEnabled,
      social,
      isCreator,
      notificationSettings
    } = res

    return dispatch({
      type: SET_ACCOUNT,
      payload: {
        name,
        userId,
        email,
        emailVerified,
        profileImageUrl,
        coverImageUrl,
        isAdultEnabled,
        social,
        isCreator,
        notificationSettings
      }
    })
  }
}

export function updateAccount(params: {
  name: string
  userId: string
  userIcon: File | undefined
  cover: File | undefined
  social: Social | undefined
}) {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const _state = getState()
    const { name, userId, userIcon, cover, social } = params

    const formData = new FormData()
    formData.append('name', name)
    formData.append('userId', userId)
    if (userIcon !== undefined) {
      formData.append('userIcon', userIcon)
    }
    if (cover !== undefined) {
      formData.append('cover', cover)
    }
    if (social !== undefined) {
      formData.append(
        'twitterShow',
        social.twitter !== undefined && social.twitter.show ? '1' : '0'
      )
    }

    const res = await axios<{ success: boolean; profileImageUrl: string; coverImageUrl: string }>(
      {
        method: 'put',
        url: '/manage/account',
        data: formData
      },
      { dispatch }
    )

    if (!res.success) {
      throw new Error()
    }

    return dispatch({
      type: SET_ACCOUNT,
      payload: {
        name: params.name,
        userId: params.userId,
        profileImageUrl: res.profileImageUrl,
        coverImageUrl: res.coverImageUrl,
        social: params.social
      }
    })
  }
}

export default {
  fetchAccount,
  updateAccount
}
