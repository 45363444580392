import NextImage from 'next/image'
import styled, { css } from 'styled-components'
import { Size, BorderType } from './types'
import { Person } from '@material-ui/icons'

interface UserIconBodyProps {
  url: string
  name: string
  size?: Size
  border?: BorderType
  onError?(): void
}
export default function UserIconBody({
  size = 64,
  url,
  name,
  border = null,
  onError
}: UserIconBodyProps) {
  if (url === '') {
    return (
      <DefaultIcon $size={size} border={border}>
        <Person style={{ fontSize: size - 8 }} />
      </DefaultIcon>
    )
  }
  return (
    <Icon
      $size={size}
      src={url}
      width={size}
      height={size}
      border={border}
      alt={name}
      onError={onError}
    />
  )
}

const backgroundCss = css<{ $size: Size; border: BorderType }>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-style: none;
  border: 0;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.Background};

  ${({ border, $size }) =>
    border === 'normal' &&
    css`
      width: ${$size - 4}px;
      height: ${$size - 4}px;
      border: 4px solid #f7f7f7;
      border-radius: 48px;
    `}
`

const DefaultIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.OnSurface2};
  ${backgroundCss};
`

const Icon = styled(NextImage)`
  display: block;
  object-fit: cover;
  object-position: center;

  ${backgroundCss};
`
