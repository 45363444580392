import types from './types'
import * as Actions from './actions'

import { AccountData } from '../../../types'
import { ActionType } from 'typesafe-actions'

export type DataState = AccountData | undefined | null

export interface AccountsState {
  data: DataState | undefined
}

export type AccountAction = ActionType<typeof Actions>

const accountsReducer = (state: AccountsState = { data: undefined }, action: AccountAction) => {
  switch (action.type) {
    case types.SET_ACCOUNT:
      const beforeState = state.data
      return {
        data: {
          ...beforeState,
          ...action.payload
        }
      }
    case types.SET_NOT_LOGIN_ACCOUNT:
      return { data: null }
    default:
      return state
  }
}

export default accountsReducer
