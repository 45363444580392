import types from './types'
import * as Actions from './actions'

import { ToastData } from '~/types'
import { ActionType } from 'typesafe-actions'

export type DataState = ToastData | undefined

export interface ToastState {
  data: DataState | undefined
}

export type ToastAction = ActionType<typeof Actions>

const toastReducer = (state: ToastState = { data: undefined }, action: ToastAction) => {
  switch (action.type) {
    case types.SET_TOAST:
      const { type, text, path } = action.payload
      return {
        data: {
          type,
          text,
          path
        }
      }
    case types.RESET_TOAST:
      return {
        data: undefined
      }
    default:
      return state
  }
}

export default toastReducer
