import React, { useLayoutEffect, useState, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { ToastType } from '~/types'

interface Props {
  type: ToastType
  children: React.ReactNode
}

function ToastBody({ type, children }: Props) {
  const ref = useRef<HTMLDivElement>()
  const [clientHeight, setClientHeight] = useState<number>(0)

  useLayoutEffect(() => {
    if (ref.current !== undefined && ref.current !== null) {
      setClientHeight(ref.current.clientHeight)
    }
  }, [ref])

  return (
    <StyledBody>
      <AnimateBody clientHeight={clientHeight} type={type} ref={ref}>
        {children}
      </AnimateBody>
    </StyledBody>
  )
}

export default ToastBody

// 0.4s動いて3s止まって0.4s動く
const SlideAnimation = ({ clientHeight }: { clientHeight: number }) => keyframes`
  0% {
    transform: translateY(${`-${clientHeight}px`});
  }

  10.5% {
    transform: translateY(0px);
  }

  89.4% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(${`-${clientHeight}px`});
  }
`

const StyledBody = styled.div`
  position: fixed;
  // モーダルよりもローディングスピナーよりも上
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  transform: translateZ(0);
`

const AnimateBody = styled.div<{ clientHeight: number; type: ToastType }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  display: block;
  ${({ theme }) => theme.fonts.Bold16};
  width: 100%;
  padding: 24px;
  background: ${({ type, theme }) =>
    type === 'Success'
      ? theme.semantics.Success
      : type === 'Failure'
      ? theme.semantics.Assertive
      : theme.colors.Surface3};
  color: ${({ theme }) => theme.colors.OnSurface4};
  animation: ${SlideAnimation} 3.8s ease 1 normal forwards;
  will-change: transform;
`
