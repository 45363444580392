import React from 'react'
import { createGlobalStyle } from 'styled-components'

export default function GlobalStyle() {
  return <Style />
}

const Style = createGlobalStyle`
  body {
    color: ${({ theme }) => theme.colors.OnSurface2};
  }

  * {
    font-family: 'Noto Sans JP', sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  :root #nprogress .bar {
    background: ${({ theme }) => theme.colors.Primary};
    border-radius: 0 2px 2px 0;
    height: 4px;
    width: calc(100% - 8px);
  }

  :root #nprogress .bar .peg {
    box-shadow: none;
  }
`
