import { useCallback, useState, useMemo, useEffect } from 'react'

function isResized(url: string) {
  return /resized_thumbnail/.test(url)
}

// TODO: 複数サムネに対応
export const useItemThumbnail = (imageUrl: string[], size: number, blur: boolean) => {
  const resizedImageUrl = useMemo(() => {
    /**
     * 0: "https://firebasestorage.googleapis.com/v0/b/ambass-next-stg.appspot.com/o/upload_thumbnails%2F20200926053739964c3e35a1e-bdf6-4dd5-aa7f-f631b1f28b38.jpg?alt=media"
     * 1: "https://firebasestorage.googleapis.com/v0/b/ambass-next-stg.appspot.com/o/upload_thumbnails%2F"
     * 2: "20200926053739964c3e35a1e-bdf6-4dd5-aa7f-f631b1f28b38"
     * 3: ".jpg"
     * 4: "?alt=media"
     */
    const match = imageUrl[0].match(/(https:\/\/.+upload_thumbnails%2F)(.*)(\..+)(\?alt=media)/)
    if (match === null || match.length < 5) {
      return imageUrl[0]
    }
    if (blur) {
      return `${match[1]}resized_thumbnail%2F${match[2]}_48x48${match[3]}${match[4]}`
    }
    return imageUrl[0]
  }, [imageUrl, blur])

  const [url, setUrl] = useState(resizedImageUrl)

  useEffect(() => {
    setUrl(resizedImageUrl)
  }, [resizedImageUrl])

  // アップロード直後はリサイズ画像が取得できないことがあるため、その場合は元画像URLを表示する
  const handleError = useCallback(() => {
    if (isResized(url)) {
      setUrl(imageUrl[0])
    }
  }, [url, imageUrl])

  return [url, handleError] as [typeof url, typeof handleError]
}

export const useUserIconImage = (imageUrl: string) => {
  const resizedImageUrl = useMemo(() => {
    /**
     * 0: "https://firebasestorage.googleapis.com/v0/b/ambass-next-stg.appspot.com/o/upload_user_icons%2F20200926053739964c3e35a1e-bdf6-4dd5-aa7f-f631b1f28b38.jpg?alt=media"
     * 1: "https://firebasestorage.googleapis.com/v0/b/ambass-next-stg.appspot.com/o/upload_user_icons%2F"
     * 2: "20200926053739964c3e35a1e-bdf6-4dd5-aa7f-f631b1f28b38"
     * 3: ".jpg"
     * 4: "?alt=media"
     */
    const match = imageUrl.match(/(https:\/\/.+upload_user_icons%2F)(.*)(\..+)(\?alt=media)/)
    if (match === null || match.length < 5) {
      return imageUrl
    }

    // ユーザーアイコンはサイズが多すぎてめんどくさいので176x176で統一する
    return `${match[1]}resized_thumbnail%2F${match[2]}_176x176${match[3]}${match[4]}`
  }, [imageUrl])

  const [url, setUrl] = useState(resizedImageUrl)

  useEffect(() => {
    setUrl(resizedImageUrl)
  }, [resizedImageUrl])

  // アップロード直後はリサイズ画像が取得できないことがあるため、その場合は元画像URLを表示する
  const handleError = useCallback(() => {
    if (isResized(url)) {
      setUrl(imageUrl)
    }
  }, [url, imageUrl])

  return [url, handleError] as [typeof url, typeof handleError]
}
