import { useEffect, useCallback, useMemo } from 'react'
import axios from '~/lib/axios'
import { useRouter } from 'next/router'
import { useDispatch } from '~/lib/hooks/redux'
import { sendLoginEvent, sendSignUpEvent } from '~/lib/event'
import { useToast } from '~/lib/hooks/toast'
import { useBusy } from '~/lib/hooks/busy'
import { getAuth, getRedirectResult, getAdditionalUserInfo } from 'firebase/auth'
import { showLoadingForRedirectKey } from '~/lib/storage'
import { UserRegisterApiResponse } from '~/types/api'

function useNeedCheckRedirect() {
  const router = useRouter()

  // 特定のページではサインアップのリダイレクトのチェックを行わない
  const withoutCheckPages = [
    /\/manage\/sales+/,
    /\/signup\/email\/register/,
    /\/signup\/email\/callback/,
    /\/signup\/email\/verify/
  ]
  const isWithoutCheckPage = useMemo(
    () => withoutCheckPages.some(ignore => ignore.test(router.pathname)),
    [router]
  )
  return !isWithoutCheckPage
}

export default function SignUpWithRedirect() {
  const router = useRouter()
  const dispatch = useDispatch()
  const [_, startBusy, endBusy] = useBusy()
  const [__, setToast] = useToast()
  const needCheck = useNeedCheckRedirect()

  const handleRedirect = useCallback(async () => {
    try {
      // リダイレクトから帰ってきたらローディングUIを出す
      // 本来はgetRedirectResultで判定したいが、数秒かかるので先にSessionStorageを見て表示する
      const showLoading = sessionStorage.getItem(showLoadingForRedirectKey)
      if (showLoading !== null) {
        startBusy()
        sessionStorage.removeItem(showLoadingForRedirectKey)
      }

      // 認証情報を取得する
      const auth = getAuth()
      const result = await getRedirectResult(auth)

      // リダイレクトしてきたときではないとき（ほとんどのケース）
      if (result === null) {
        endBusy()
        return
      }

      startBusy()

      // userはFirebaseAuthに登録されている情報なので最新版ではないはず
      const {
        user: { uid, photoURL }
      } = result

      // ↓はTwitterの最新の情報のはず
      const { username, profile } = getAdditionalUserInfo(result)

      const token = await auth.currentUser.getIdToken(true)

      const { email, name } = profile as { email: string; name: string }

      const imageUrl =
        'profile_image_url_https' in profile
          ? (profile as { profile_image_url_https: string }).profile_image_url_https.replace(
              'normal',
              '200x200'
            )
          : photoURL

      const coverImageUrl =
        'profile_banner_url' in profile
          ? (profile as { profile_banner_url: string }).profile_banner_url
          : ''

      const { alreadyRegistered, requireEmail } = await axios<UserRegisterApiResponse>(
        {
          method: 'post',
          url: '/users/register',
          data: {
            uid,
            userId: username,
            name,
            imageUrl,
            coverImageUrl,
            email,
            token
          }
        },
        { dispatch }
      )

      if (requireEmail) {
        // メールアドレス登録で使うので一時的に保存しておく
        localStorage.setItem(
          'temporary_signup',
          JSON.stringify({ userId: username, name, imageUrl, coverImageUrl })
        )
        router.push({ pathname: `/signup/email/register`, query: { returnUrl: router.asPath } })
        return
      }

      if (alreadyRegistered) {
        sendLoginEvent('twitter')
      } else {
        sendSignUpEvent('twitter')
      }
      router.reload()
    } catch (e) {
      // パスワードリセットを行うとTwitter連携が一度切れてしまう
      if (/auth\/account-exists-with-different-credential/.test(e.message)) {
        setToast(
          'お手数ですがメールアドレスとパスワードでログインして、メニューからTwitter再連携を行ってください',
          'Failure'
        )
      } else {
        setToast('ログインに失敗しました。お手数ですが、もう1度最初からお試しください。', 'Failure')
      }

      throw e
    } finally {
      endBusy()
    }
  }, [])

  useEffect(() => {
    if (needCheck) {
      handleRedirect()
    }
  }, [])

  return null
}
