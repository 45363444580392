import { useEffect, useState } from 'react'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { DefaultSeo } from 'next-seo'
import 'modern-css-reset'
import { ThemeProvider } from 'styled-components'
import '../styles/global.css'
import '~/styles/slick-overwrite.css'
import { lightTheme, darkTheme } from '../styles/theme'
import useDarkMode from 'use-dark-mode'
import '@fontsource/noto-sans-jp/900.css'

import '~/lib/firebase'
import { useAuthAccount } from '~/lib/hooks/auth'
import { useLoadingSpinner } from '~/lib/hooks/spinner'
import ViewHistoryProvider from '~/components/provider/ViewHistoryProvider'
import Toast from '~/components/atoms/Toast'
import LoadingSpinner from '~/components/atoms/LoadingSpinner'
import { ErrorStatusProvider } from '~/components/atoms/ErrorStatusProvider'
import Footer from '~/components/molecules/Footer'
import Body from '~/components/templates/Body'
import SignUpWithRedirect from '~/components/organisms/SignUpWithRedirect'
import CheckEmailVerifiedAccount from '~/components/organisms/CheckEmailVerifiedAccount'
import FloatFooter from '~/components/molecules/FloatFooter'
import GlobalStyle from '~/components/templates/GlobalStyle'
import Maintenance from '~/components/templates/Maintenance'
import { wrapper } from '~/state/store'
import { SWRConfig } from 'swr'

import '~/lib/nprogress'

function App({ Component, pageProps }: AppProps) {
  useAuthAccount()
  const siteDescription =
    'Ambass（アンバス）はクリエイターのデジタル作品を購入・布教できるギフティングプラットフォームです🎁推しの作品をみんなに広めてクリエイターを応援しよう🙌'
  const [dark, setDark] = useState(false)
  const router = useRouter()
  const darkMode = useDarkMode()
  useEffect(() => {
    setDark(darkMode.value)
  }, [darkMode])

  const [loading, _, endLoading] = useLoadingSpinner()

  // ページが切り替わるときはローディングを閉じる
  useEffect(() => {
    if (loading) {
      endLoading()
    }
  }, [router])

  return (
    <>
      <Head>
        <meta name='viewport' content='"width=device-width,initial-scale=1.0' />
      </Head>
      <DefaultSeo
        titleTemplate='%s - Ambass'
        defaultTitle='Ambass'
        description={siteDescription}
        openGraph={{
          url: process.env.origin,
          type: 'website',
          title: 'Ambass',
          description: `${siteDescription}`,
          images: [
            {
              url: `${process.env.origin}/images/logo_ogp.png`,
              width: 1200,
              height: 630,
              alt: 'logo'
            }
          ],
          site_name: 'Ambass'
        }}
        twitter={{
          site: '@ambass_me',
          cardType: 'summary_large_image'
        }}
      />
      <ThemeProvider theme={dark ? darkTheme : lightTheme}>
        <ErrorStatusProvider>
          <ViewHistoryProvider>
            <SWRConfig
              value={{
                // 別ウィンドウにフォーカスしてからまたフォーカスしたときなどに連続でリクエスト飛ばさないため
                focusThrottleInterval: 30000,
                // APIエラーになっても2回までしかリトライしない
                errorRetryCount: 2
              }}
            >
              <Body>
                <Maintenance>
                  <Component {...pageProps} />
                </Maintenance>
              </Body>
              <Footer />
              <FloatFooter />
              <GlobalStyle />
              <Toast />
              <LoadingSpinner />
              <SignUpWithRedirect />
              <CheckEmailVerifiedAccount />
            </SWRConfig>
          </ViewHistoryProvider>
        </ErrorStatusProvider>
      </ThemeProvider>
    </>
  )
}

export default wrapper.withRedux(App)
