import { useEffect, useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import { useSelector, useDispatch } from '~/lib/hooks/redux'
import { useBusy } from '~/lib/hooks/busy'
import { useToast } from '~/lib/hooks/toast'
import { fetchAccount } from '~/state/ducks/accounts/operations'
import { getAccountData } from '~/state/ducks/accounts/selectors'
import cookies from 'js-cookie'
import axios from '~/lib/axios'
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithRedirect,
  linkWithRedirect,
  getRedirectResult,
  TwitterAuthProvider,
  Auth,
  UserCredential
} from 'firebase/auth'
import { clearPaymentIdItems } from '~/lib/storage'

export const useAuthAccount = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const auth = getAuth()
    onAuthStateChanged(auth, user => {
      if (user) {
        setCookieForRefreshSession()
      } else {
        resetCookieForRefreshSession()
      }
    })
    dispatch(fetchAccount())
  }, [])
}

// 定期的にSessionを更新するためにTokenをCookieに保存しておく
// このTokenだけが漏れても特に問題はない
async function setCookieForRefreshSession() {
  const auth = getAuth()
  const token = await auth.currentUser.getIdToken()
  // 1時間
  const expires = new Date(new Date().getTime() + 60 * 60 * 1000)
  cookies.set('rt', token, {
    expires,
    path: '/',
    secure: process.env.NODE_ENV !== 'development'
  })
}

function resetCookieForRefreshSession() {
  cookies.remove('rt', {
    path: '/'
  })
}

export const useSignout = () => {
  const account = useSelector(getAccountData)
  const dispatch = useDispatch()

  const handleSignOut = useCallback(
    async (callbackUrl?: string) => {
      try {
        const auth = getAuth()
        await signOut(auth)

        await axios({ method: 'post', url: '/users/logout' }, { dispatch })

        // リフレッシュ用のTokenも消す
        document.cookie = `rt=;max-age=0;path=/`

        // 決済IDも削除しておく
        clearPaymentIdItems(account.userId)

        // 色々リフレッシュする必要があり、バグの温床になるので遷移かリロードさせる
        if (callbackUrl !== undefined) {
          location.href = callbackUrl
        } else {
          location.reload()
        }
      } catch (e) {
        console.error(e.message)
      }
    },
    [account, dispatch]
  )

  return handleSignOut
}

const provider = new TwitterAuthProvider()

// 再認証
export const useReAuthWithTwitter = (callback: (result: UserCredential) => void) => {
  const account = useSelector(getAccountData)
  const [redirectResult, setRedirectResult] = useState<UserCredential>()
  const router = useRouter()
  const [_, startBusy, endBusy] = useBusy()

  const handleRedirect = useCallback(
    (result: UserCredential) => {
      callback(result)
    },
    [callback]
  )

  const handleSignUpWithTwitter = useCallback(
    async (auth: Auth) => {
      startBusy()
      try {
        await signInWithRedirect(auth, provider)
      } finally {
        endBusy()
      }
    },
    [router]
  )

  const handleCheckRedirect = useCallback(async () => {
    const auth = getAuth()
    const result = await getRedirectResult(auth)
    setRedirectResult(result)
  }, [getAuth])

  useEffect(() => {
    handleCheckRedirect()
  }, [])

  useEffect(() => {
    if (
      account !== undefined &&
      account !== null &&
      account.social !== undefined &&
      (account.social.twitter !== undefined || account.social.twitterId !== undefined) &&
      redirectResult !== undefined
    ) {
      // リダイレクトしてきたとき
      if (redirectResult !== null) {
        handleRedirect(redirectResult)
      } else {
        const auth = getAuth()
        handleSignUpWithTwitter(auth)
      }
    }
  }, [account, redirectResult])
}

// Twitter連携&再連携
export const useLinkWithTwitter = (callback: (result: UserCredential) => void) => {
  const account = useSelector(getAccountData)
  const [redirectResult, setRedirectResult] = useState<UserCredential>()
  const router = useRouter()
  const [_, startBusy, endBusy] = useBusy()
  const [__, setToast] = useToast()

  const handleRedirect = useCallback(
    (result: UserCredential) => {
      callback(result)
    },
    [callback]
  )

  const handleSignInWithTwitter = useCallback(
    async (auth: Auth) => {
      startBusy()
      try {
        await linkWithRedirect(auth.currentUser, provider)
      } catch (e) {
        if (/auth\/provider-already-linked/.test(e.message)) {
          setToast('このアカウントはすでにX連携がされています', 'Failure')
        } else {
          throw e
        }
      } finally {
        endBusy()
      }
    },
    [router]
  )

  const handleCheckRedirect = useCallback(async () => {
    const auth = getAuth()
    const result = await getRedirectResult(auth)
    setRedirectResult(result)
  }, [getAuth])

  useEffect(() => {
    handleCheckRedirect()
  }, [])

  useEffect(() => {
    if (account !== undefined && account !== null && redirectResult !== undefined) {
      // リダイレクトしてきたとき
      if (redirectResult !== null) {
        handleRedirect(redirectResult)
      } else {
        const auth = getAuth()
        handleSignInWithTwitter(auth)
      }
    }
  }, [account, redirectResult])
}
