import React from 'react'
import styled, { css } from 'styled-components'

export const colors = ['primary', 'secondary', 'cancel', 'warning', 'overlay'] as const
export const sizes = ['s', 'm'] as const
export type Color = (typeof colors)[number]
export type Size = (typeof sizes)[number]

interface RawProps {
  label: string
  color?: Color
  size?: Size
  disabled?: boolean
}

interface Props extends RawProps {
  onClick(): void
}

function Button({ label, color = 'secondary', size = 'm', disabled, onClick }: Props) {
  return (
    <ButtonContainer>
      <StyledButton color={color} size={size} type='button' onClick={onClick} disabled={disabled}>
        {label}
      </StyledButton>
    </ButtonContainer>
  )
}

export default Button

// ボタンにonClickの挙動をさせたくなくてガワだけほしいときはこっちを使う
export function RawStyleButton({ label, color = 'secondary', size = 'm', disabled }: RawProps) {
  return (
    <ButtonContainer>
      <StyledButtonDiv color={color} size={size} disabled={disabled}>
        {label}
      </StyledButtonDiv>
    </ButtonContainer>
  )
}

export const ButtonContainer = styled.div`
  display: flex;
  text-align: center;
`

export const ButtonStyle = css<{
  color: Color
  size: Size
  disabled: boolean | undefined
}>`
  ${({ theme, size }) => (size === 's' ? theme.fonts.Bold14 : theme.fonts.Bold20)};
  background-color: ${({ color, theme }) =>
    color === 'primary'
      ? theme.colors.Primary
      : color === 'overlay'
      ? theme.colors.Surface3
      : color === 'secondary'
      ? theme.colors.OnSurface1
      : 'transparent'};
  color: ${({ color, theme }) =>
    color === 'primary' || color === 'overlay'
      ? theme.colors.White
      : color === 'warning'
      ? theme.colors.Primary
      : color === 'cancel'
      ? theme.colors.OnSurface5
      : theme.colors.OnSurface2};
  border-radius: ${({ size }) => (size === 's' ? '20px' : '28px')};
  padding: ${({ size }) => (size === 's' ? '12px 16px' : '16px 24px')};
  border: 0;
  flex: ${({ size }) => (size === 's' ? 'none' : '1 1 auto')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `};
`

const StyledButton = styled.button`
  ${ButtonStyle}
`

const StyledButtonDiv = styled.div`
  ${ButtonStyle}
`
