import React, { useRef, useState, useEffect, useCallback } from 'react'
import styled, { keyframes } from 'styled-components'
import MainContentLayout from '~/components/templates/MainContentLayout'

import Header from './Header'

export function SlideBody({ onClose, children }: { onClose(): void; children: React.ReactNode }) {
  const slideRef = useRef<HTMLDivElement>()
  const mask = useRef<HTMLDivElement>()
  const [slideHeight, setSlideHeight] = useState<number>(0)

  const handleClickMask = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === mask.current) {
      onClose()
    }
  }, [])

  useEffect(() => {
    if (slideRef.current !== undefined && slideRef.current !== null) {
      setSlideHeight(slideRef.current.clientHeight)
    }
  }, [slideRef])

  return (
    <Mask onClick={handleClickMask} ref={mask}>
      <Slide clientHeight={slideHeight} ref={slideRef}>
        <MainContentLayout>
          <SlideContent>{children}</SlideContent>
        </MainContentLayout>
      </Slide>
    </Mask>
  )
}

export function ModalBody({
  title,
  scrollable,
  onClose,
  children
}: {
  title?: string
  scrollable: boolean
  onClose(): void
  children: React.ReactNode
}) {
  return (
    <CoverAll scrollable={scrollable}>
      <MainContentLayout>
        <ModalBodyContainer>
          <StyledHeader title={title} onClose={onClose} />
          {children}
        </ModalBodyContainer>
      </MainContentLayout>
    </CoverAll>
  )
}

export const CoverAll = styled.div<{ scrollable: boolean }>`
  background: ${({ theme }) => theme.colors.Background};
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  padding: 32px;
  z-index: 1;

  ${({ scrollable }) =>
    scrollable &&
    `
      overflow: auto;
    `}
`

const ModalBodyContainer = styled.div`
  width: 100%;
`

const Mask = styled.div`
  background: ${({ theme }) => theme.colors.Surface3};
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  cursor: pointer;
`

const StyledHeader = styled(Header)`
  margin: 24px -8px 48px;
`

const SlideAnimation = ({ clientHeight }: { clientHeight: number }) => keyframes`
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(${`-${clientHeight}px`});
  }
`

const Slide = styled.div<{ clientHeight: number }>`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.Background};
  width: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  padding: 32px 32px 48px;
  animation: ${({ clientHeight }) => SlideAnimation({ clientHeight })} 0.4s forwards;
  cursor: initial;
`

const SlideContent = styled.div`
  flex: 1 0 auto;
  width: 100%;
`
