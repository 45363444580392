import styled from 'styled-components'
import React, { useState, useCallback } from 'react'
import { useSelector } from '~/lib/hooks/redux'
import { getAccountData } from '~/state/ducks/accounts/selectors'
import SignupModal from './SignupModal'

interface Props {
  children: React.ReactNode
}

export default function SignupModalManager({ children }: Props) {
  const [show, setShow] = useState(false)
  const account = useSelector(getAccountData)
  const isNotLogin = account === null

  const handleClickChildren = useCallback(async () => {
    if (isNotLogin) {
      setShow(true)
    }
  }, [account])

  const handleClose = useCallback(async () => {
    if (isNotLogin) {
      setShow(false)
    }
  }, [account])

  return (
    <>
      <ChildWrapper onClick={handleClickChildren}>{children}</ChildWrapper>
      {isNotLogin && <SignupModal show={show} onClose={handleClose} />}
    </>
  )
}

const ChildWrapper = styled.div``
