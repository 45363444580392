import React from 'react'
import styled from 'styled-components'
import { calculateColumns } from '~/types/consts'

export default function MainContentLayout({ children }: { children: React.ReactNode }) {
  return <Container>{children}</Container>
}

const Container = styled.div`
  max-width: ${calculateColumns(6)}px;
  margin: 0 auto;
`
