import React from 'react'
import styled, { css } from 'styled-components'

export const sizes = [40, 48, 56] as const
export type Size = (typeof sizes)[number]

interface Props {
  Icon: React.ReactNode
  size?: Size
  overImage?: boolean
  hasError?: boolean
  disabled?: boolean
  className?: string
  onClick?(): void
}

export default function IconButton({
  size = 40,
  overImage = false,
  hasError = false,
  disabled = false,
  Icon,
  onClick,
  className
}: Props) {
  return (
    <Container
      size={size}
      overImage={overImage}
      hasError={hasError}
      className={className}
      disabled={disabled}
      onClick={onClick}
    >
      {Icon}
    </Container>
  )
}

const Container = styled.div<{
  size: number
  overImage: boolean
  hasError: boolean
  disabled: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  background: ${({ hasError, overImage, theme }) =>
    hasError
      ? theme.colors.Assertive
      : overImage
      ? theme.colors.Surface3
      : theme.colors.OnSurface1};
  color: ${({ hasError, overImage, theme }) =>
    hasError
      ? theme.colors.OnSurface4
      : overImage
      ? theme.colors.OnSurface4
      : theme.colors.OnSurface2};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `};
`
