import React from 'react'
import styled from 'styled-components'

export default function Maintenance({ children }: { children: React.ReactNode }) {
  if (process.env.NEXT_PUBLIC_ENABLE_MAINTENANCE === 'true') {
    return <Container>メンテナンスモード</Container>
  }

  return <>{children}</>
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 128px;

  ${({ theme }) => theme.fonts.Bold24};
`
