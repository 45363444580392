import { useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'
import { useSelector, useDispatch } from '~/lib/hooks/redux'
import { getAuth } from 'firebase/auth'
import axios from '~/lib/axios'

import { getAccountData } from '~/state/ducks/accounts/selectors'

export default function CheckEmailVerifiedAccount() {
  const account = useSelector(getAccountData)
  const dispatch = useDispatch()
  const router = useRouter()
  const ignoreUrls = [/\/signup\/email\/send/, /\/signup\/email\/callback/, /\/auth\/email\/verify/]

  // メールアドレス未認証ユーザーの場合はログインしていたら/signup/email/sendにリダイレクトさせる
  const checkEmailVerified = useCallback(async () => {
    if (
      account !== undefined &&
      account !== null &&
      account.emailVerified === false &&
      !ignoreUrls.some(ignore => ignore.test(router.pathname))
    ) {
      const auth = getAuth()
      if (auth.currentUser !== null && auth.currentUser.emailVerified) {
        // DB不整合の場合、DBのemailVerifiedをtrueにする（確認メールを踏んだときにログインしていなければ起きる）
        await axios<{ success: true }>(
          { method: 'put', url: '/users/register/verify_email' },
          { dispatch }
        )
        location.href = '/'
      } else {
        // 単なるメール未認証ユーザーの場合は認証メールを送るページにリダイレクトさせる
        location.href = '/signup/email/send'
      }
    }
  }, [account, router, dispatch])

  useEffect(() => {
    checkEmailVerified()
  }, [account, router])

  return null
}
