import { useCallback } from 'react'
import { useLoadingSpinner } from '~/lib/hooks/spinner'
import Modal from '~/components/molecules/Modal'
import LoadingSpinnerBody from './LoadingSpinnerBody'
import styled from 'styled-components'

export default function Toast() {
  const [loading, _] = useLoadingSpinner()

  const noop = useCallback(() => {}, [])

  return (
    <Modal type='raw' show={loading} onClose={noop}>
      <Container>
        <Mask />
        <LoadingSpinnerBody />
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  position: fixed;
  // すべてのモーダルより上にきてほしいため
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Mask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background: ${({ theme }) => theme.colors.Background};
`
