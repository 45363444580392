import styled from 'styled-components'
import { useHideFooter } from '~/lib/hooks/footer'
import { useSelector } from '~/lib/hooks/redux'
import { getAccountData } from '~/state/ducks/accounts/selectors'
import { getDownloadData } from '~/state/ducks/download/selectors'
import UserIcon from '~/components/atoms/UserIcon'
import SignupModal from '~/components/organisms/SignupModal'
import { Home, Folder } from '@material-ui/icons'
import MenuBody from './MenuBody'
import { useRouter } from 'next/router'
import Download from './Download'

export default function Footer() {
  const router = useRouter()
  // AccountData | undefined だが、色々試してもうごく型解決してくれなかった
  const account = useSelector(getAccountData)
  const downloadData = useSelector(getDownloadData)
  const activePage =
    router.pathname === '/'
      ? 'home'
      : // TODO: ちゃんとパスまで見たほうが良さそう
      account !== undefined && account !== null && account.userId === router.query.user_id
      ? 'mypage'
      : router.pathname === '/manage/library'
      ? 'library'
      : undefined

  // 特定のページ（モーダル風）ではフッターは非表示にする
  const isHiddenPage = useHideFooter()

  if (account === undefined || isHiddenPage) {
    return null
  }

  const isLoggedIn = account !== null

  const hasDownload = downloadData !== undefined

  return (
    <FixedContent hasDownload={hasDownload}>
      {hasDownload && (
        <Download
          itemUid={downloadData.item.itemUid}
          title={downloadData.item.title}
          fileInfo={downloadData.files}
        />
      )}
      <Container>
        <IconContainer isLoggedIn={isLoggedIn}>
          <MenuBody
            active={activePage === 'home'}
            icon={<Home style={{ fontSize: 48 }} />}
            pathname='/'
          />

          {isLoggedIn ? (
            <>
              <MenuBody
                active={activePage === 'library'}
                icon={<Folder style={{ fontSize: 48 }} />}
                pathname='/manage/library'
              />

              <MenuBody
                active={activePage === 'mypage'}
                icon={
                  <UserIcon
                    size={48}
                    userId={account.userId}
                    url={account.profileImageUrl}
                    name={account.name}
                    link={false}
                  />
                }
                pathname={`/users/${account.userId}`}
              />
            </>
          ) : (
            <LoginLabelContainer>
              <SignupModal>
                <LoginLabel>ログイン・新規登録</LoginLabel>
              </SignupModal>
            </LoginLabelContainer>
          )}
        </IconContainer>
      </Container>
    </FixedContent>
  )
}

const FixedContent = styled.div<{ hasDownload: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 72px;
  position: fixed;
  bottom: 32px;
  pointer-events: none;

  ${({ hasDownload, theme }) => hasDownload && theme.semantics.Overlay2};

  ${({ hasDownload, theme }) =>
    hasDownload &&
    `
    // Overlay2とbackgroundが異なるので↓で上書き
    background: ${theme.colors.Surface2};
    display: flex;
    flex-flow: column;
    height: auto;
    bottom: 0;
    padding-bottom: 32px;
    pointer-events: all;
  `}
`

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 72px;
  ${({ theme }) => theme.semantics.Overlay1};
  background: ${({ theme }) => theme.colors.Surface3};
  border-radius: 36px;
  margin: 0 auto;
  pointer-events: auto;
`

const IconContainer = styled.div<{ isLoggedIn: boolean }>`
  display: grid;
  grid-template-columns: ${({ isLoggedIn }) => (isLoggedIn ? 'repeat(3, 48px);' : '48px 1fr')};
  grid-template-rows: auto;
  grid-gap: 12px;
  height: 100%;
`

const LoginLabelContainer = styled.div`
  display: flex;
  align-items: center;
`
const LoginLabel = styled.p`
  ${({ theme }) => theme.fonts.Bold16};
  color: ${({ theme }) => theme.colors.OnSurface4};
  cursor: pointer;
`
