import { useMemo } from 'react'
import { useRouter } from 'next/router'

// 現在のページでフッターを非表示にするかどうか
export function useHideFooter() {
  const router = useRouter()

  // 特定のページ（モーダル風）ではフッターは非表示にする
  const hidePages = [
    /\/manage\/items\/.+/,
    /\/manage\/upload/,
    /\/manage\/account/,
    /\/manage\/sales+/,
    /\/manage\/notifications/,
    /\/manage\/email/,
    /\/manage\/password/,
    /\/users\/.+\/terms/,
    /\/manage\/purchases/,
    /\/signup\/email\/register/,
    /\/signup\/email\/callback/,
    /\/signup\/email\/verify/,
    /\/signup\/email\/send/
  ]
  const isHiddenPage = useMemo(
    () => hidePages.some(ignore => ignore.test(router.pathname)),
    [router]
  )
  return isHiddenPage
}
