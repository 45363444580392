import { useCallback } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import Link from 'next/link'

import { useBusy } from '~/lib/hooks/busy'
import { useDispatch } from '~/lib/hooks/redux'
import { FileInfo } from '~/types'
import axios from '~/lib/axios'
import Button from '~/components/atoms/Button'

interface Props {
  itemUid: string
  title: string
  fileInfo: FileInfo[]
}
export default function Download({ itemUid, title, fileInfo }: Props) {
  const router = useRouter()
  const [busy, startBusy, endBusy] = useBusy()
  const dispatch = useDispatch()

  const handleDownload = useCallback(async () => {
    startBusy()
    const { url } = await axios<any>(
      {
        url: `/items/${encodeURI(`${itemUid}`)}/download`
      },
      { dispatch }
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileInfo[0].name)
    link.click()
    endBusy()
  }, [router, fileInfo])

  return (
    <FixedContainer>
      <ContentBody>
        <ItemInfo>
          <Title>{title}</Title>
          <LinkWrapper>
            <StyledLink href='/manage/library' passHref>
              ライブラリーにあります
            </StyledLink>
          </LinkWrapper>
        </ItemInfo>
        <Button
          label='ダウンロード'
          onClick={handleDownload}
          disabled={busy}
          color='primary'
          size='s'
        />
      </ContentBody>
    </FixedContainer>
  )
}
const FixedContainer = styled.div`
  padding: 24px;
`

const ContentBody = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 16px;
  max-width: 375px;
  margin: 0 auto;
`

const ItemInfo = styled.div`
  display: flex;
  flex-flow: column;
  overflow: hidden;
`

const Title = styled.span`
  ${({ theme }) => theme.fonts.Bold16};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 243px;
`

const StyledLink = styled(Link)`
  ${({ theme }) => theme.fonts.Bold14};
  color: ${({ theme }) => theme.colors.OnSurface3};
`

// リンク領域を文字の範囲に狭めるため必要
const LinkWrapper = styled.div`
  font-size: 0;
`
