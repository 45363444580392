import styled from 'styled-components'
import { getAuth, signInWithRedirect, TwitterAuthProvider } from 'firebase/auth'
import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { useBusy } from '~/lib/hooks/busy'
import { showLoadingForRedirectKey } from '~/lib/storage'

import Button from '~/components/atoms/Button'
import Modal from '~/components/molecules/Modal'

const provider = new TwitterAuthProvider()

interface Props {
  show: boolean
  onClose(): void
}

export default function SignupModal({ show, onClose }: Props) {
  const router = useRouter()
  const [busy, startBusy, endBusy] = useBusy()

  const handleSignUpWithTwitter = useCallback(async () => {
    startBusy()
    try {
      sessionStorage.setItem(showLoadingForRedirectKey, 'true')
      const auth = getAuth()
      await signInWithRedirect(auth, provider)
    } finally {
      endBusy()
    }
  }, [router])

  const handleSignInWithEmailPassword = useCallback(async () => {
    onClose()
    router.push({ pathname: '/login/email', query: { returnUrl: router.asPath } })
  }, [router])

  const handleSignupWithEmailPassword = useCallback(async () => {
    onClose()
    router.push({ pathname: '/signup/email', query: { returnUrl: router.asPath } })
  }, [router])

  return (
    <Modal show={show} type='slide' onClose={onClose}>
      <Container>
        <Title>アカウント登録・ログインが必要です</Title>
        <Buttons>
          <Button
            label='Xで続ける'
            color='primary'
            onClick={handleSignUpWithTwitter}
            disabled={busy}
          />
          <Button
            label='メールアドレスで新規登録'
            onClick={handleSignupWithEmailPassword}
            disabled={busy}
          />
          <Button
            label='メールアドレスでログイン'
            onClick={handleSignInWithEmailPassword}
            disabled={busy}
          />
          <Button label='あとで' color='cancel' onClick={onClose} disabled={busy} />
        </Buttons>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  margin: 0 auto;
  text-align: center;
`

const Title = styled.p`
  ${({ theme }) => theme.fonts.Bold20};
  color: ${({ theme }) => theme.colors.OnSurface2};
  margin: 0 0 24px;
`

const Buttons = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-rows: auto auto;
`
