import { useCallback } from 'react'
import { setLoading as setLoadingOperation } from '~/state/ducks/loading/operations'
import { useSelector, useDispatch } from '~/lib/hooks/redux'
import { getLoadingData } from '~/state/ducks/loading/selectors'

export const useLoadingSpinner = () => {
  const loading = useSelector(getLoadingData)
  const dispatch = useDispatch()

  const startLoading = useCallback(async () => {
    dispatch(setLoadingOperation({ loading: true }))
  }, [dispatch])

  const endLoading = useCallback(async () => {
    dispatch(setLoadingOperation({ loading: false }))
  }, [dispatch])

  return [loading, startLoading, endLoading] as [
    typeof loading,
    typeof startLoading,
    typeof endLoading
  ]
}
