import React from 'react'
import styled, { keyframes } from 'styled-components'
import Spinner from './Spinner.svg'

export default function LoadingSpinnerBody() {
  return (
    <Contaner>
      <StyledSpinner src={Spinner} />
    </Contaner>
  )
}

const Rotation = keyframes`
  0% {
    transform:rotate(0);
  }
  100%{
    transform:rotate(360deg);
  }
`

const Contaner = styled.div`
  display: inline-block;
  font-size: 0;
`

const StyledSpinner = styled.img`
  animation: 0.8s linear infinite ${Rotation};
`
