import { useCallback, useState } from 'react'
import { useLoadingSpinner } from '~/lib/hooks/spinner'

export const useBusy = () => {
  const [busy, setBusy] = useState(false)
  const [_, startLoading, endLoading] = useLoadingSpinner()

  const startBusy = useCallback(() => {
    setBusy(true)
    startLoading()
  }, [])

  const endBusy = useCallback(() => {
    setBusy(false)
    endLoading()
  }, [])

  return [busy, startBusy, endBusy] as [typeof busy, typeof startBusy, typeof endBusy]
}
