import axios, { AxiosRequestConfig } from 'axios'
import { parseCookies } from 'nookies'
import { isEnabledCSRFToken, refreshCSRFToken } from './csrfToken'
import { GetServerSidePropsContext } from 'next'
import { Dispatch } from 'redux'
import { setToast } from '~/state/ducks/toast/operations'

export default async function axiosClient<T>(
  options: AxiosRequestConfig & { forceDontRefreshCSRFToken?: boolean },
  // TODO: SSR側もnext-redux-wrapperを使ってdispatch渡すようにする
  ctx: GetServerSidePropsContext | { dispatch: Dispatch }
): Promise<T> {
  const isBrowser = typeof window !== 'undefined'

  // ブラウザで実行した場合はFirebaseUserトークンとCSRFトークンの再発行を行う
  if (isBrowser) {
    if (!isEnabledCSRFToken() && !options.forceDontRefreshCSRFToken) {
      const { dispatch } = ctx as { dispatch: any }
      await refreshCSRFToken(dispatch)
    }
  }

  const cookies = parseCookies(isBrowser ? undefined : (ctx as GetServerSidePropsContext))

  const baseURL = isBrowser
    ? '/api'
    : process.env.VERCEL_URL !== undefined
    ? `https://${process.env.VERCEL_URL}/api`
    : 'http://localhost:96/api'

  // ブラウザではCSRFトークンをヘッダーに、SSRではCookieをヘッダーにそれぞれ付与する
  // ブラウザ側はwithCredentialsでcookieが付与されるが、SSRの場合は自前でつけなくてはならない
  const headers = isBrowser
    ? cookies !== null && cookies.tt !== undefined
      ? { 'X-CSRF-Token': cookies.tt }
      : {}
    : {
        cookie: Object.entries(cookies)
          .map(([key, value]) => `${key}=${value};`)
          .join('')
      }

  const client = axios.create({
    baseURL,
    timeout: 30000,
    headers,
    withCredentials: true
  })

  try {
    const res = await client(options)
    return res.data
  } catch (e) {
    // ブラウザからのAPI呼び出しにレスポンスにエラーメッセージが付与されていた場合はalertで表示する
    if (isBrowser && e.response !== undefined && e.response.data.message) {
      const { dispatch } = ctx as { dispatch: any }
      dispatch(setToast({ text: e.response.data.message, type: 'Failure' }))
    }
    throw e
  }
}
