import { useEffect, useState, useRef } from 'react'
import { useRouter } from 'next/router'
import { useToast } from '~/lib/hooks/toast'
import ToastBody from './ToastBody'

export default function Toast() {
  const router = useRouter()
  const [toast, _, resetToast] = useToast()
  const [show, setShow] = useState<boolean>(false)
  const prevShow = useRef<boolean>()
  const timerId = useRef<any>()

  // トーストを表示したあとで別ページに遷移したときにresetすることで、再度表示されることがないようにしている
  useEffect(() => {
    const show =
      // トーストが存在する場合は表示
      toast !== undefined &&
      // ただし遷移してから表示のときは、トースト設定したパスから遷移していれば表示
      (toast.path === undefined || router.pathname !== toast.path)

    if (show && !prevShow.current) {
      setShow(true)

      // 連続でトーストが出るなどで前のタイマーが残っている場合はクリアしておく
      if (timerId.current !== undefined) {
        clearTimeout(timerId.current)
      }

      // アニメーションが終わった頃にリセットする
      timerId.current = setTimeout(() => {
        setShow(false)
        resetToast()
        timerId.current = undefined
      }, 4000)
    }
  }, [toast, router])

  if (!show || toast === undefined) {
    return null
  }

  return <ToastBody type={toast.type}>{toast.text}</ToastBody>
}
