import { FileInfo, ItemData } from '~/types'
import { Dispatch } from 'redux'
import { SET_DOWNLOAD, RESET_DOWNLOAD } from './types'

export function setDownload(params: { files: FileInfo[]; item: ItemData }) {
  return async (dispatch: Dispatch) => {
    const { files, item } = params

    return dispatch({
      type: SET_DOWNLOAD,
      payload: {
        files,
        item
      }
    })
  }
}

export function resetDownload() {
  return async (dispatch: Dispatch) => {
    return dispatch({
      type: RESET_DOWNLOAD,
      payload: {}
    })
  }
}

export default {
  setDownload,
  resetDownload
}
