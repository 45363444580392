import axios from './axios'
import cookies from 'js-cookie'
import { Dispatch } from 'redux'

interface CSRFTokenApiResponse {
  tt: string
}

export function isEnabledCSRFToken(): boolean {
  const tt = cookies.get('tt')
  return tt !== undefined
}

export async function refreshCSRFToken(dispatch: Dispatch) {
  const { tt } = await axios<CSRFTokenApiResponse>(
    {
      url: '/users/tt',
      // これを入れないとCookieにttがない時に無限ループに陥る
      forceDontRefreshCSRFToken: true
    },
    { dispatch }
  )
  // 1時間
  const expires = new Date(new Date().getTime() + 60 * 60 * 1000)
  cookies.set('tt', tt, {
    path: '/',
    expires,
    secure: process.env.NODE_ENV !== 'development'
  })
}
