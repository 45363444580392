import { ButtonStyle, Color, Size, ButtonContainer } from '~/components/atoms/Button'
import Link from 'next/link'
import styled from 'styled-components'

interface Props {
  href: string
  label: string
  color?: Color
  size?: Size
  disabled?: boolean
}

export default function ButtonLikeLink({
  href,
  label,
  color = 'secondary',
  size = 'm',
  disabled
}: Props) {
  return (
    <ButtonContainer>
      <StyledLink href={href} passHref color={color} size={size} disabled={disabled}>
        {label}
      </StyledLink>
    </ButtonContainer>
  )
}

const StyledLink = styled(Link)`
  ${ButtonStyle};

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
  `}
`
