import { Dispatch } from 'redux'
import { SET_LOADING } from './types'

export function setLoading(params: { loading: boolean }) {
  return async (dispatch: Dispatch) => {
    const { loading } = params

    return dispatch({
      type: SET_LOADING,
      payload: {
        loading
      }
    })
  }
}

export default {
  setLoading
}
