import types from './types'
import * as Actions from './actions'

import { ActionType } from 'typesafe-actions'
import { FileInfo, ItemData } from '~/types'

export type DataState = {
  files: FileInfo[]
  item: ItemData
}

export interface DownloadState {
  data: DataState | undefined
}

export type DownloadAction = ActionType<typeof Actions>

const downloadReducer = (state: DownloadState = { data: undefined }, action: DownloadAction) => {
  switch (action.type) {
    case types.SET_DOWNLOAD:
      const { files, item } = action.payload
      return {
        data: {
          files,
          item
        }
      }
    case types.RESET_DOWNLOAD:
      return {
        data: undefined
      }
    default:
      return state
  }
}

export default downloadReducer
