import { useRouter } from 'next/router'
import { useCallback } from 'react'
import {
  setToast as setToastOperation,
  resetToast as resetToastOperation
} from '~/state/ducks/toast/operations'
import { useSelector, useDispatch } from '~/lib/hooks/redux'
import { getToastData } from '~/state/ducks/toast/selectors'
import { ToastType } from '~/types'

export const useToast = () => {
  const router = useRouter()
  const toast = useSelector(getToastData)
  const dispatch = useDispatch()

  // nextPageがtrueの場合はページ遷移後にトーストを表示する
  // nextPageがfalse or 未指定の場合はその場で表示する
  const setToast = useCallback(
    async (text: string, type: ToastType = 'Notification', nextPage: boolean = false) => {
      await dispatch(resetToastOperation())
      const toastData = nextPage ? { text, type, path: router.pathname } : { text, type }
      dispatch(setToastOperation(toastData))
    },
    [dispatch]
  )

  const resetToast = useCallback(() => {
    dispatch(resetToastOperation())
  }, [dispatch])

  return [toast, setToast, resetToast] as [typeof toast, typeof setToast, typeof resetToast]
}
