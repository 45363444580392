import { createSelector } from 'reselect'
import { DataState } from './reducers'
import { RootState } from '../types'

export const downloadDataSelector = (state: Pick<RootState, 'download'>) => state.download.data

export const getDownloadData = createSelector(downloadDataSelector, (state: DataState) => {
  return state
})

export default {
  getDownloadData
}
