import 'styled-components'

const colors = {
  White: 'hsl(0, 0%, 100%)',
  Gray1: 'hsl(200, 30%, 98%)',
  Gray2: 'hsl(200, 30%, 95%)',
  Gray3: 'hsl(200, 30%, 90%)',
  Gray3A: 'hsla(200, 30%, 90%, 0.75)',
  Gray4: 'hsl(200, 15%, 75%)',
  Gray5: 'hsl(200, 15%, 60%)',
  Gray6: 'hsl(200, 15%, 30%)',
  Gray7: 'hsl(200, 15%, 25%)',
  Gray7A: 'hsla(200, 15%, 25%, 0.75)',
  Gray8: 'hsl(200, 15%, 20%)',
  Gray8A: 'hsla(200, 15%, 20%, 0.75)',
  Gray9: 'hsl(200, 15%, 15%)',
  Gray10: 'hsl(200, 15%, 10%)'
}

export const lightTheme = {
  fonts: {
    Regular14: {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '24px'
    },
    Bold14: {
      fontWeight: 900,
      fontSize: '14px',
      lineHeight: '16px'
    },
    Bold16: {
      fontWeight: 900,
      fontSize: '16px',
      lineHeight: '24px'
    },
    Bold20: {
      fontWeight: 900,
      fontSize: '20px',
      lineHeight: '24px'
    },
    Bold24: {
      fontWeight: 900,
      fontSize: '24px',
      lineHeight: '32px'
    }
  },
  colors: {
    Red: '#E55A50',
    White: '#ffffff',

    Surface1: colors.Gray2,
    Surface2: colors.Gray8A,
    Surface3: colors.Gray7A,

    OnSurface1: colors.Gray3,
    OnSurface2: colors.Gray7,
    OnSurface3: colors.Gray5,
    OnSurface4: colors.White,
    OnSurface5: colors.Gray4,

    Background: colors.Gray1,

    Primary: '#FF3369',

    Assertive: '#E55A50',

    Category: {
      All: '#FF3369',
      Illustration: '#E65A50',
      Manga: '#E6A050',
      Novel: '#43BF75',
      Music: '#4CBDD9',
      Voice: '#477CCC',
      Idol: '#E65A50',
      Cosplay: '#F2DD55',
      Photo: '#A0CC47',
      Video: '#43BF75',
      Game: '#9643BF',
      Trpg: '#20B9DF',
      Other: '#477CCC'
    }
  },
  semantics: {
    Overlay1: {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      backdropFilter: 'blur(32px)'
    },
    Overlay2: {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      backdropFilter: 'blur(96px)'
    },
    Blur: {
      background: colors.Gray7A,
      backdropFilter: 'blur(16px)'
    },

    Success: '#A0CC47',
    Pending: '#E5A050',
    Assertive: '#E55A50',

    Link: '#FF3369'
  }
}

export const darkTheme: AppTheme = {
  fonts: lightTheme.fonts,
  colors: {
    ...lightTheme.colors,

    Surface1: colors.Gray9,
    Surface2: colors.Gray8A,
    Surface3: colors.Gray7A,

    OnSurface1: colors.Gray7,
    OnSurface2: colors.Gray3,
    OnSurface3: colors.Gray5,
    OnSurface4: colors.Gray3,
    OnSurface5: colors.Gray6,

    Background: colors.Gray10
  },
  semantics: lightTheme.semantics
}

type AppTheme = typeof lightTheme

declare module 'styled-components' {
  interface DefaultTheme extends AppTheme {}
}
