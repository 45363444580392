export function getPaymentIdKey(userId: string, itemUid: string, type: string) {
  return `pId_${userId}_${itemUid}_${type}`
}

export function clearPaymentIdItems(userId: string) {
  const paymentIdItemKeys = getPaymentIdItemKeys(userId)
  for (const key of paymentIdItemKeys) {
    sessionStorage.removeItem(key)
  }
}

export function getPaymentIdItemKeys(userId: string) {
  const paymentIdkeys = []
  const keys = Object.keys(sessionStorage)

  const reg = new RegExp(`pId_${userId}`)

  for (const key of keys) {
    if (reg.test(key)) {
      paymentIdkeys.push(key)
    }
  }

  return paymentIdkeys
}

export const showLoadingForRedirectKey = 'show_loading_for_redirect'
