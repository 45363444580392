import styled from 'styled-components'

const ActiveBar = styled.div`
  width: 8px;
  height: 8px;
  background: ${({ theme }) => theme.colors.Primary};
  border-radius: 50%;
  margin: 0;
  border-style: none;
  align-self: center;
  grid-area: dot;
`
export default ActiveBar
