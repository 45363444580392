import { initializeApp } from 'firebase/app'

const config = process.env.firebase
initializeApp({
  apiKey: config.apiKey,
  authDomain: config.authDomain,
  databaseURL: `https://${config.projectId}.firebaseio.com`,
  projectId: config.projectId,
  storageBucket: `${config.projectId}.appspot.com`,
  messagingSenderId: config.messagingSenderId,
  appId: config.appId
})
