import { useContext } from 'react'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { useHideFooter } from '~/lib/hooks/footer'
import { useSelector } from '~/lib/hooks/redux'
import { getDownloadData } from '~/state/ducks/download/selectors'
import { getAccountData } from '~/state/ducks/accounts/selectors'
import Button from '~/components/atoms/Button'
import ButtonLikeLink from '~/components/atoms/ButtonLikeLink'
import { ErrorStatusContext } from '~/components/atoms/ErrorStatusProvider'
import SignupModal from '~/components/organisms/SignupModal'
import Logo from './Logo.svg'

export default function Footer() {
  const downloadData = useSelector(getDownloadData)
  const hasDownload = downloadData !== undefined
  const account = useSelector(getAccountData)

  const {
    state: { status }
  } = useContext(ErrorStatusContext)
  const hasError = status !== 200

  // 特定のページ（モーダル風）ではフッターは非表示にする
  const isHiddenPage = useHideFooter()

  if (isHiddenPage) {
    return null
  }

  return (
    <Container>
      {!hasError && (
        <MainContainer>
          <LogoIcon src={Logo} />

          <MainText>
            Ambassでデジタル作品を販売してみませんか？
            収益を得られるとともに、ファンがあなたの作品を布教できるようになります。あなたの活動に貢献してくれたユーザーを把握してお返しすることもできます。
          </MainText>

          {account !== undefined && account !== null ? (
            <ButtonLikeLink href='/manage/upload' label='作品を販売する' size='s' />
          ) : (
            //未ログインの場合は、ログインモーダル
            <SignupModal>
              <Button onClick={() => {}} label='作品を販売する' size='s' />
            </SignupModal>
          )}
          <SupportText>
            Ambassに参加してほしいクリエイターがいる場合は
            <SupportLink href='https://docs.google.com/forms/d/e/1FAIpQLSfnO2nxiJqhQ8L15fCSfqxfolf9vaZoKmQRtQ9j8ZxKg8_aRw/viewform'>
              リクエストをお送りください
            </SupportLink>
            。
          </SupportText>
        </MainContainer>
      )}
      <BottomContainer hasDownload={hasDownload}>
        <Help href='/help' passHref>
          ヘルプ
        </Help>
        <Copyright>&copy; 2021 September6 LLC</Copyright>
      </BottomContainer>
    </Container>
  )
}

const Container = styled.div`
  max-width: 640px;
  margin: 0 auto;
`

const MainContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  // Bodyコンポーネントのbottom分引いてる（モーダルライクなページでbottomが必要なため。しかしいい感じにしたい）
  padding: ${96 - 24}px 32px 32px;
`

const LogoIcon = styled.img`
  margin-bottom: 24px;
  width: 48px;
  height: 48px;
`

const MainText = styled.p`
  ${({ theme }) => theme.fonts.Bold16};
  color: ${({ theme }) => theme.colors.OnSurface3};
  margin-bottom: 16px;
`

const SupportText = styled.p`
  margin-top: 32px;
  ${({ theme }) => theme.fonts.Bold16};
  color: ${({ theme }) => theme.colors.OnSurface3};
`

const SupportLink = styled.a`
  color: ${({ theme }) => theme.semantics.Assertive};
`

const BottomContainer = styled.div<{ hasDownload: boolean }>`
  display: grid;
  grid-gap: 16px;
  justify-content: center;
  justify-items: center;
  align-content: flex-start;
  grid-template-areas:
    'help'
    'copyright';
  height: ${({ hasDownload }) => (hasDownload ? '288px' : '200px')};
  padding: 32px 16px;
  border-radius: 24px 24px 0px 0px;
  background: ${({ theme }) => theme.colors.Surface1};
`

const gridContentCss = css`
  ${({ theme }) => theme.fonts.Bold14};
  margin: 0;
  color: ${({ theme }) => theme.colors.OnSurface3};
`

const Help = styled(Link)`
  ${gridContentCss}
  grid-area: help;
`

const Copyright = styled.p`
  ${gridContentCss}
  grid-area: copyright;
  color: ${({ theme }) => theme.colors.OnSurface5};
`
