import React from 'react'
import styled from 'styled-components'

export default function Body({ children }: { children: React.ReactNode }) {
  return <BodyRoot>{children}</BodyRoot>
}

const BodyRoot = styled.div`
  min-height: calc(100vh - 208px);
  padding-bottom: 24px;
`
