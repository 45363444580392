import { createSelector } from 'reselect'
import { DataState } from './reducers'
import { RootState } from '../types'

export const toastDataSelector = (state: Pick<RootState, 'toast'>) => state.toast.data

export const getToastData = createSelector(toastDataSelector, (state: DataState) => {
  return state
})

export default {
  getToastData
}
