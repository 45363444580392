import { createStore, applyMiddleware, combineReducers, compose, Store } from 'redux'
import * as reducers from './ducks'
import { RootState } from './ducks/types'
import { createWrapper, Context } from 'next-redux-wrapper'
import thunk from 'redux-thunk'

interface ExtendedWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
}
declare let window: ExtendedWindow

const composeReduxDevToolsEnhancers =
  (process.env.NEXT_PUBLIC_ENABLE_REDUX_DEV_TOOLS === 'true' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const middlewares = [thunk]

const rootReducer = combineReducers(reducers)
const makeStore = (_context: Context) =>
  createStore(rootReducer, composeReduxDevToolsEnhancers(applyMiddleware(...middlewares)))

export const wrapper = createWrapper<Store<RootState>>(makeStore)
